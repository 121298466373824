module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans:400,600,700","Roboto:400,700,900","Oswald:700,900"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NV9GSBR","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/@ropto/ropto-session-gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"enablePosts":false,"baseUrl":"https://sessions.ropto.com","pageDetails":{"pageId":"447748","pageName":"HP002","brand":"MyMoneyBack","pageVariant":"V001"}},
    }]
