// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-ropto-gatsby-theme-ropto-legal-src-templates-legal-jsx": () => import("./../node_modules/@ropto/gatsby-theme-ropto-legal/src/templates/Legal.jsx" /* webpackChunkName: "component---node-modules-ropto-gatsby-theme-ropto-legal-src-templates-legal-jsx" */),
  "component---src-templates-article-jsx": () => import("./../src/templates/Article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-complaints-policy-js": () => import("./../src/pages/complaints-policy.js" /* webpackChunkName: "component---src-pages-complaints-policy-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-js": () => import("./../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sign-up-js": () => import("./../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-sign-up-2-js": () => import("./../src/pages/sign-up2.js" /* webpackChunkName: "component---src-pages-sign-up-2-js" */),
  "component---src-pages-thankyou-js": () => import("./../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

